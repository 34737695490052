.board {
    &-body {
        display: flex;
        align-items: center;
        transform: rotate3d(-10, 0, 0, 0deg);
        transition: transform .5s, margin .5s;
        transform-origin: center;
        gap: calc(24px * var(--device-pixel-ratio));
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: calc(25px * var(--device-pixel-ratio));
        &-left,
        &-right {
            gap: calc(8px * var(--device-pixel-ratio));
            display: flex;
            flex-direction: column;
            width: calc(80px * var(--device-pixel-ratio));
            position: relative;
            &-item-side-bets {
                position: absolute;
                text-shadow: 1px 1px 2px #000000;
                color: rgba(255, 255, 255, 0.4);
                text-transform: uppercase;
                font-size: calc(12px * var(--device-pixel-ratio));
                letter-spacing: calc(0.45px * var(--device-pixel-ratio));
                font-weight: 600;
                font-family: SF Compact Text Heavy, SF Compact Text;
            }
        }
        &-left {
            order: 1;
            .board-body-left-top-book {
                background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                border-radius: 12px;
                min-width: calc(64px*var(--device-pixel-ratio));
                padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                text-align: center;
                color: #fff;
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: calc(12px * var(--device-pixel-ratio));
                &.green {
                    color: rgba(50, 215, 75, 1);
                }
                &.red {
                    color: rgba(255, 69, 58, 1);
                }
            }
            .board-body-left-bottom-book {
                background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                border-radius: 12px;
                min-width: calc(64px*var(--device-pixel-ratio));
                padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                text-align: center;
                color: #fff;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: calc(12px * var(--device-pixel-ratio));
                &.green {
                    color: rgba(50, 215, 75, 1);
                }
                &.red {
                    color: rgba(255, 69, 58, 1);
                }
            }
        }
        &-right {
            order: 3;
            .board-body-right-top-book {
                background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                border-radius: 12px;
                min-width: calc(64px*var(--device-pixel-ratio));
                padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                text-align: center;
                color: #fff;
                position: absolute;
                top: -15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: calc(12px * var(--device-pixel-ratio));
                &.green {
                    color: rgba(50, 215, 75, 1);
                }
                &.red {
                    color: rgba(255, 69, 58, 1);
                }
            }
            .board-body-right-bottom-book {
                background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                border-radius: 12px;
                min-width: calc(64px*var(--device-pixel-ratio));
                padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                text-align: center;
                color: #fff;
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);
                font-size: calc(12px * var(--device-pixel-ratio));
                &.green {
                    color: rgba(50, 215, 75, 1);
                }
                &.red {
                    color: rgba(255, 69, 58, 1);
                }
            }
        }
        &-center {
            width: calc(530px * var(--device-pixel-ratio));
            order: 2;
            position: relative;
            .board-body-center-bottom-book {
                position: absolute;
                bottom: 0;
                width: 100%;
                .book-seven-down {
                    background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                    border-radius: 12px;
                    min-width: calc(64px*var(--device-pixel-ratio));
                    padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    bottom: -5px;
                    left: 15%;
                    font-size: calc(12px * var(--device-pixel-ratio));
                    &.green {
                        color: rgba(50, 215, 75, 1);
                    }
                    &.red {
                        color: rgba(255, 69, 58, 1);
                    }
                }
                .book-seven {
                    background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                    border-radius: 12px;
                    min-width: calc(64px*var(--device-pixel-ratio));
                    padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: calc(12px * var(--device-pixel-ratio));
                    &.green {
                        color: rgba(50, 215, 75, 1);
                    }
                    &.red {
                        color: rgba(255, 69, 58, 1);
                    }
                }
                .book-seven-up {
                    background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
                    border-radius: 12px;
                    min-width: calc(64px*var(--device-pixel-ratio));
                    padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    bottom: -5px;
                    right: 15%;
                    font-size: calc(12px * var(--device-pixel-ratio));
                    &.green {
                        color: rgba(50, 215, 75, 1);
                    }
                    &.red {
                        color: rgba(255, 69, 58, 1);
                    }
                }
            }
        }
    }
}

.enabled-bet-areas {
    .board {
        &-body {
            &-left,
            &-right {
                &-item-side-bets {
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}

.threeD {
    .board {
        &-body {
            transform: rotate3d(-10, 0, 0, -45deg) translate(-4px, 12px) scaleX(0.98) scaleY(1.03);
        }
    }
}

.middlePc {
    .board {
        &-body {
            display: flex;
            align-items: center;
            transform: rotate3d(-10, 0, 0, 0deg);
            transition: transform .5s, margin .5s;
            transform-origin: center;
            gap: calc(24px * var(--device-pixel-ratio));
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: calc(25px * var(--device-pixel-ratio));
            &-left,
            &-right {
                gap: calc(8px * var(--device-pixel-ratio));
                display: flex;
                flex-direction: column;
                width: calc(80px * var(--device-pixel-ratio));
                &-item-side-bets {
                    position: absolute;
                    text-shadow: 1px 1px 2px #000000;
                    color: rgba(255, 255, 255, 0.4);
                    text-transform: uppercase;
                    font-size: calc(15px * var(--device-pixel-ratio));
                    letter-spacing: calc(0.45px * var(--device-pixel-ratio));
                    font-weight: 600;
                    font-family: SF Compact Text Heavy, SF Compact Text;
                }
            }
            &-left {
                order: 1;
            }
            &-right {
                order: 3;
            }
            &-center {
                width: calc(530px * var(--device-pixel-ratio));
                order: 2;
            }
        }
    }
}

.smallPc {
    .bet-area {
        .board-body {
            align-items: flex-end;
            gap: calc(16px * var(--device-pixel-ratio));
            display: flex;
            justify-content: space-between;
            height: calc(212px * var(--device-pixel-ratio));
            width: calc(670px * var(--device-pixel-ratio));
            &-left {
                order: 1;
                &-item {
                    &-side-bets {
                        left: calc(5px * var(--device-pixel-ratio));
                    }
                }
            }
            &-right {
                order: 2;
                &-item {
                    &-side-bets {
                        right: calc(5px * var(--device-pixel-ratio));
                    }
                }
            }
            &-left,
            &-right {
                width: calc(90px * var(--device-pixel-ratio));
                &-item {
                    &-side-bets {
                        font-size: calc(13px * var(--device-pixel-ratio));
                        ;
                    }
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &-center {
                width: calc(420px * var(--device-pixel-ratio));
                .middle-area {
                    width: 100%;
                    height: calc(114px * var(--device-pixel-ratio));
                    ;
                }
            }
        }
    }
}

.tab {
    .board {
        &-body {
            align-items: flex-end;
            gap: calc(16px * var(--device-pixel-ratio));
            ;
            &-left,
            &-right {
                &-item {
                    &-side-bets {
                        font-size: calc(12px * var(--device-pixel-ratio));
                        ;
                    }
                }
            }
            &-left {
                &-item {
                    &-side-bets {
                        left: calc(5px * var(--device-pixel-ratio));
                        ;
                    }
                }
            }
            &-right {
                &-item {
                    &-side-bets {
                        right: calc(5px * var(--device-pixel-ratio));
                        ;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    .bet-area {
        .board {
            &-body {
                align-items: flex-end;
                flex-direction: row;
                justify-content: center;
                gap: 2vh;
                flex-wrap: nowrap;
                width: 100%;
                margin-bottom: 3vw;
                &-left,
                &-right {
                    width: 14vw;
                    transition: bottom 0.5s;
                    position: relative;
                    &-item {
                        & .odd-element {
                            width: 100%;
                            height: 100%;
                        }
                        &-side-bets {
                            font-size: 7px;
                        }
                    }
                }
                &-left {
                    &-item {
                        &-side-bets {
                            left: 0;
                        }
                    }
                    .board-body-left-top-book {
                        min-width: calc(40px * var(--device-pixel-ratio));
                        padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                        top: -12px;
                        font-size: calc(9px * var(--device-pixel-ratio));
                    }
                    .board-body-left-bottom-book {
                        min-width: calc(40px * var(--device-pixel-ratio));
                        padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                        bottom: -12px;
                        font-size: calc(9px * var(--device-pixel-ratio));
                    }
                }
                &-right {
                    &-item {
                        &-side-bets {
                            right: 0;
                        }
                    }
                    .board-body-right-top-book {
                        min-width: calc(40px * var(--device-pixel-ratio));
                        padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                        top: -12px;
                        font-size: calc(9px * var(--device-pixel-ratio));
                    }
                    .board-body-right-bottom-book {
                        min-width: calc(40px * var(--device-pixel-ratio));
                        padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                        bottom: -12px;
                        font-size: calc(9px * var(--device-pixel-ratio));
                    }
                }
                &-center {
                    width: 50vw;
                    .board-body-center-bottom-book {
                        .book-seven-down {
                            left: 5%;
                            bottom: -8px;
                            min-width: calc(40px * var(--device-pixel-ratio));
                            padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                            font-size: calc(9px * var(--device-pixel-ratio));
                        }
                        .book-seven-up {
                            right: 5%;
                            bottom: -8px;
                            min-width: calc(40px * var(--device-pixel-ratio));
                            padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                            font-size: calc(9px * var(--device-pixel-ratio));
                        }
                        .book-seven {
                            bottom: -8px;
                            min-width: calc(40px * var(--device-pixel-ratio));
                            padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
                            font-size: calc(9px * var(--device-pixel-ratio));
                        }
                    }
                }
            }
        }
        &.threeD {
            .board-body {
                transform: unset;
            }
        }
    }
}