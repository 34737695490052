@import "base/colors";
@import "./../fonts/svgFonts/fonts/iconfont";
@import "base/reset";
@import "components/tooltip";
@import "components/buttons";
@import "layouts/header";
@import "components/videoStream";
@import "components/templateButton";
@import "layouts/mainLayout";
@import "components/videoStream";
@import "components/winnerList";
@import "components/history";
@import "components/modal";
@import "components/infoPopUp";
@import "components/rightMenu";
@import "components/Cards";
@import "components/connectMessage";
@import "components/loading";
@import "components/alertMessage";
@import "components/gameHistory";
@import "components/roundInfo";
@import "components/elementLoading";
@import "components/hideToolBar";
@import "components/userWon";
@import "components/timer";
@import "components/userCenterInfo";
@import "components/roundHistory";
@import "components/load";
@import "components/board";
@import "components/activeBets";
@import "components/nominal";
@import "components/betArena";
@import "./base/responsive.scss";



.full-size {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 20;
}