.board-middle-area {
  display: block;
}
.board-middle-area-768, .board-middle-area-1280, .board-middle-area-mobile{
  display: none;
}
.odd-element{
  display: block;
}
.odd-element-1280, .odd-element-768, .odd-element-mobile {
  display: none;
}
.smallPc {
  .board-middle-area-1280 {
    display: block;
  }
  .board-middle-area-768, .board-middle-area, .board-middle-area-mobile{
    display: none;
  }
  .odd-element, .odd-element-768, .odd-element-mobile {
    display: none;
  }
  .odd-element-1280{
    display: block;
  }

  .board {
    width: calc(710px * var(--device-pixel-ratio));
    margin-left: calc(35px * var(--device-pixel-ratio));
    margin-right: calc(15px * var(--device-pixel-ratio));
  }
  .bet-board {
    width: calc(678px * var(--device-pixel-ratio));
  }

}

.tab {
  .board-middle-area-768 {
    display: block;
  }
  .board-middle-area-1280, .board-middle-area, .board-middle-area-mobile{
    display: none;
  }
  .odd-element-1280, .odd-element, .odd-element-mobile {
    display: none;
  }
  .odd-element-768 {
    display: block;
  }
  .board {
    order: 1;
    width: calc(728px * var(--device-pixel-ratio));
    margin-right: 0;
    margin-left: 0;
    margin-bottom: calc(54px * var(--device-pixel-ratio));
  }
}

@media only screen and (max-width: 540px) {
  .board-middle-area-mobile {
    display: block;
  }
  .board-middle-area-1280, .board-middle-area, .board-middle-area-768{
    display: none;
  }
  .odd-element-1280, .odd-element-768, .odd-element {
    display: none;
  }
  .odd-element-mobile{
    display: block;
  }
  .fon {
    height: 68.065vh;
    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2DB3 22%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    border-radius: 4px;
  }
}