.history {
  width: 100%;
  & .history-table {
    margin: 0 calc(20px * var(--device-pixel-ratio)) 0 calc(20px * var(--device-pixel-ratio));
    &-date-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: calc(20px * var(--device-pixel-ratio));
      padding-bottom: calc(20px * var(--device-pixel-ratio));
      margin-bottom: calc(10px * var(--device-pixel-ratio));
      border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
      & .react-datepicker-wrapper {
            width: calc(113px * var(--device-pixel-ratio));
            & .react-datepicker__input-container {
              width: 100%;
              & input {
                font: 300 calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Light";
                letter-spacing: calc(0.56px * var(--device-pixel-ratio));
                color: $datePicker-input-color;
                border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
                border-radius: calc(20px * var(--device-pixel-ratio));
                background: transparent;
                width: 100%;
                padding:  calc(5px * var(--device-pixel-ratio))  calc(15px * var(--device-pixel-ratio));
                &:active, &:focus {
                  outline: 0;
                  box-shadow: none;
                  border-color: $datePicker-header-bottom-border-color;
                }
              }
            }
          }

      & .react-datepicker-popper {
            &[data-placement^=bottom] {
              padding-top: calc(20px * var(--device-pixel-ratio));
            }
            & .react-datepicker {
              width: calc(270px * var(--device-pixel-ratio));
              border-radius: calc(12px * var(--device-pixel-ratio));
              background-color: transparent;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                backdrop-filter: blur(2px);
                z-index: -1;
                border-radius: calc(12px * var(--device-pixel-ratio));
                background-color: $datePicker-bg-color;
              }

              & .react-datepicker__triangle {
                &:before, &:after {
                  content: unset;
                }
              }

              & .react-datepicker__month-container {
                background-color: $datePicker-bg-color;
                width: 100%;

                & .react-datepicker__header {
                  border-color: $datePicker-header-bottom-border-color;
                  background-color: transparent;

                  & .react-datepicker__day-names {
                    & .react-datepicker__day-name {
                      font: 500 calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      color: $datePicker-header-weekDay-color
                    }
                  }

                  & .react-datepicker__current-month,
                  & .react-datepicker-time__header,
                  & .react-datepicker-year-header {
                    font: 500 calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    color: $datePicker-header-weekDay-color
                  }

                  & .datePicker-header {
                    position: relative;
                    padding: calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: space-between;
                    width: 100%;
                    font: calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    color: $datePicker-header-color;
                    text-shadow: 0px 0px 1px $datePicker-header-shadow-color;

                    & .monthsArrow, & .yearsArrow {
                      width: calc(20px * var(--device-pixel-ratio));
                      height: calc(20px * var(--device-pixel-ratio));
                      border-radius: calc(2px * var(--device-pixel-ratio));
                      background-color: transparent;
                      border-color: transparent;
                      cursor: pointer;
                      &:hover {
                        background-color: rgba(84, 104, 126, 0.4);
                      }
                      &:active {
                        background-color: rgba(84, 104, 126, 0.9);
                      }

                      &.monthsArrow-right, &.yearsArrow-right {
                        transform: rotate(180deg);

                      }
                    }
                  }
                }

                & .react-datepicker__month {
                  & .react-datepicker__week {
                    display: flex;

                    & .react-datepicker__day {
                      width: 100%;
                      height: calc(24px * var(--device-pixel-ratio));
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font: 500 calc(14px * var(--device-pixel-ratio))/calc(22px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                      color: $datePicker-header-mount-weekDay-color;

                      &.react-datepicker__day--outside-month {
                        color: rgb($datePicker-header-mount-weekDay-color, 0.5);
                      }
                    }

                    & .react-datepicker__day--selected,
                    & .react-datepicker__day--in-selecting-range,
                    & .react-datepicker__day--in-range,
                    & .react-datepicker__month-text--selected,
                    & .react-datepicker__month-text--in-selecting-range,
                    & .react-datepicker__month-text--in-range,
                    & .react-datepicker__quarter-text--selected,
                    & .react-datepicker__quarter-text--in-selecting-range,
                    & .react-datepicker__quarter-text--in-range,
                    & .react-datepicker__year-text--selected,
                    & .react-datepicker__year-text--in-selecting-range,
                    & .react-datepicker__year-text--in-range,
                    & .react-datepicker__day:hover,
                    & .react-datepicker__month-text:hover,
                    & .react-datepicker__quarter-text:hover,
                    & .react-datepicker__year-text:hover {
                      border-radius: calc(2px * var(--device-pixel-ratio));
                      background-color: $datePicker-header-mount-weekDay-selected-color;
                    }

                    & .react-datepicker__day:active,
                    & .react-datepicker__month-text:active,
                    & .react-datepicker__quarter-text:active,
                    & .react-datepicker__year-text:active {
                      background-color: rgba(84, 104, 126, 0.9);
                      border-radius: calc(2px * var(--device-pixel-ratio));
                    }
                  }
                }
              }

              & .react-datepicker__today-button {
                background: transparent;
                border-color: $datePicker-header-bottom-border-color;
                text-shadow: 0px 0px calc(1px * var(--device-pixel-ratio)) $datePicker-footer-text-shadow-color;
                color: $datePicker-footer-text-color;
                padding: calc(15px * var(--device-pixel-ratio)) 0;
                &:hover {
                  background: rgba(84, 104, 126, 0.4);
                }

                &:active {
                  background: rgba(84, 104, 126, 0.9);
                }
              }
            }

          }
      
      }

    & table {
      width: 100%;
      border-collapse: collapse;

      & thead {
        & tr {
          & th {
            font: calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            letter-spacing: calc(0.68px * var(--device-pixel-ratio));
            color: $history-table-head-text-color;
            text-align: left;
            padding-bottom: calc(14px * var(--device-pixel-ratio));

            &:nth-child(1) {
              width: 50%;
            }

            &:nth-child(2) {
              width: 25%;
            }
          }
        }
      }
    }

    &-body {
      overflow: auto;
      height: calc((770px) * var(--device-pixel-ratio));

      & table {
        & tbody {
          scrollbar-width: none;
          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0, 0, 0, 0);
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 calc(6px * var(--device-pixel-ratio)) rgba(0, 0, 0, .1);
          }

          & tr {
            color: $history-table-body-row-color;
            cursor: pointer;

            &.sum {
              border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
            }

            & td {
              font: calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Medium";
              letter-spacing: calc(0.56px * var(--device-pixel-ratio));
              padding: calc(10px * var(--device-pixel-ratio)) 0 calc(10px * var(--device-pixel-ratio));
              height: calc(58px * var(--device-pixel-ratio));
              vertical-align: bottom;

              &:nth-child(1) {
                width: 50%;

                & .info-box {
                  gap: calc(8px * var(--device-pixel-ratio));
                  height: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-direction: column;

                  & .round-id {
                    font: 300 calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Light";
                    letter-spacing: calc(0.48px * var(--device-pixel-ratio));
                    color: #EAEAEC;
                  }
                }
              }

              &:nth-child(2) {
                width: 25%;
              }
            }
          }
        }
      }
    }

    &-no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid rgba(135, 135, 148, 0.5);

      & .icon {
        width: calc(120px * var(--device-pixel-ratio));
        height: calc(74px * var(--device-pixel-ratio));
        margin: auto;
      }

      & .title {
        text-align: center;
        font: bold calc(20px * var(--device-pixel-ratio))/ calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing: 0;
        color: #878794;
        padding: calc(16px * var(--device-pixel-ratio)) 0 calc(12px * var(--device-pixel-ratio)) 0;
      }
    }

  }
}

.middlePc {
  & .history {
    & .history-table {
      margin: 0 calc(16px * var(--device-pixel-ratio)) 0 calc(16px * var(--device-pixel-ratio));
      &-body {
        overflow: auto;
        height: calc((495px) * var(--device-pixel-ratio));
        & table {
          & tbody {
            & tr {
              & td {
                &:nth-child(1) {
                  & .info-box {
                    & .round-id {
                      font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.smallPc {
  .history {
    & .history-table {
      margin: 0 calc(16px * var(--device-pixel-ratio)) 0 calc(16px * var(--device-pixel-ratio));
      & table {
        & thead {
          & tr {
            & th {
              font: bold calc(17px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Bold";
            }
          }
        }
      }
      &-body {
        height: calc((525px) * var(--device-pixel-ratio));
      }
    }
  }
}

.tab {
  & .history {
    & .history-table {
      margin: 0 calc(16px * var(--device-pixel-ratio)) 0 calc(16px * var(--device-pixel-ratio));
      &-body {
        height: calc((700px) * var(--device-pixel-ratio));
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .history {
    & .history-table {
      &-body {
        height: calc(100vh - 13.19vh - 205px);
      }

      & table {
        & thead {
          & tr {
            & th {
              letter-spacing: 0.68px;
              font-size: 2.93vw;
              padding: calc(8px * var(--device-pixel-ratio)) 0 calc(8px * var(--device-pixel-ratio));

              &:nth-child(1) {
                width: 46%;
              }

              &:nth-child(2) {
                width: 30%;
              }
            }
          }
        }

        & tbody {
          & tr {
            & td {
              font-size: 2.6vw;
              line-height: 12px;
              letter-spacing: 0.56px;
              white-space: nowrap;
              padding-bottom: 12px;
              height: 40px;

              &:nth-child(1) {
                width: 46%;

                & .info-box {
                  gap: 6px;

                  & .round-id {
                    font-size: 2.13vw;
                    line-height: 10px;
                    letter-spacing: calc(0.32px * var(--device-pixel-ratio));
                  }
                }
              }

              &:nth-child(2) {
                width: 30%;
              }
            }
          }
        }
      }

      .loadingHistory {
        margin: 5px 0;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      &-no-data {
        & .icon {
          width: calc(80px * var(--device-pixel-ratio));
          height: calc(50px * var(--device-pixel-ratio));
          margin: auto;
        }

        & .title {
          text-align: center;
          font: bold calc(15px * var(--device-pixel-ratio))/ calc(22px * var(--device-pixel-ratio)) "SF Pro Text Bold" !important;
          letter-spacing: 0;
          color: #878794;
          padding: calc(12px * var(--device-pixel-ratio)) 0 calc(8px * var(--device-pixel-ratio)) 0;
        }
      }
    }

  }
}

.history-table .react-datepicker-wrapper input {
    width: 95%;
    height: 30px;
    padding-left: 5px;
    background: rgba(135, 135, 148, 0.16) 0% 0% no-repeat padding-box;
    color: #fff;
    border: 1px solid #545454;
    border-radius: 4px;
}