.bet-area {
    width: 100%;
    height: 100%;
    perspective: calc(929px * var(--device-pixel-ratio));
    display: flex;
    justify-content: center;
}
.bet-area {
    pointer-events: none;
    .board-body {
        svg.odd-element {
            g {
                fill: rgba(83,61,113,0.2);
                stroke: rgba(122, 104, 147, 0.4);
            }
            text {
                font-family: SF Compact Text Heavy, SF Compact Text;
                &:nth-child(2) {
                    fill: rgba(255, 255, 255,0.4)
                }
                &:nth-child(3) {
                    fill: rgba(255, 204, 0, 0.4)
                }
                tspan {
                    font-family: SF Compact Text Heavy, SF Compact Text;
                }
            }
        }
        svg.middle-area {
            .middle-area {
                &-left-block {
                    path:nth-child(1) {
                        fill: rgba(0, 114, 195, 0.2);
                    }
                    path:nth-child(2) {
                        fill: rgba(94, 153, 195, 0.4);
                    }
                }
                &-center-block {
                    fill: rgba(15, 183, 0, 0.2);
                    stroke: rgba(116, 188, 110, 0.4);
                }
                &-right-block {
                    fill: rgba(193, 0, 40, 0.2);

                    path:nth-child(2) {
                        fill: rgba(195, 102, 122, 0.4);
                    }
                }
            }
            text {
                font-family: SF Compact Text Heavy, SF Compact Text;
                pointer-events: none;
            }
            tspan {
                font-family: SF Compact Text Heavy, SF Compact Text;
                pointer-events: none;
            }
            .up-text , .down-text, ._7-text{
                fill:rgba(255, 255, 255, 0.4);
                pointer-events: none;
            }
            ._7-odd-text, .up-odd-text, .down-odd-text {
                fill: rgba(255, 204, 0, 0.4);
                pointer-events: none;
            }
        }
    }
    &.enabled-bet-areas {
        pointer-events: all;
        .board-body {
            svg.odd-element {
                cursor: pointer;
                g {
                    fill: rgba(83,61,113,0.5);
                    stroke: rgba(122, 104, 147, 1);
                }
                text {
                    pointer-events: none;
                    &:nth-child(2) {
                        fill: rgba(255, 255, 255, 1)
                    }
                    &:nth-child(3) {
                        fill: rgba(255, 204, 0, 1)
                    }
                }
                &:hover , &.active {
                    g {
                        fill: rgba(83,61,113,1);
                        stroke: rgba(122, 104, 147, 1);
                    }
                }
            }
            svg.middle-area {
                .middle-area {
                    &-left-block {
                        cursor: pointer;
                        path:nth-child(1) {
                            fill: rgba(0,114,195, 0.5)
                        }
                        &:hover , &.active {
                            path:nth-child(1) {
                                fill: rgba(0,114,195, 1)
                            }
                        }
                        
                    }
                    &-center-block {
                        cursor: pointer;
                        fill: rgba(15, 183, 0, 0.5);
                        stroke: rgba(116, 188, 110, 1);
                        &:hover , &.active {
                            fill: rgba(15, 183, 0, 1);
                        }
                    }
                    &-right-block {
                        cursor: pointer;
                        fill: rgba(193, 0, 40, 0.5);

                        path:nth-child(2) {
                            fill: rgba(195, 102, 122, 0.4);
                        }
                        &:hover , &.active {
                            fill: rgba(193, 0, 40, 1);

                        path:nth-child(2) {
                            fill: rgba(195, 102, 122, 1);
                        }
                        }
                    }
                }
                .up-text , .down-text, ._7-text{
                    fill:rgba(255, 255, 255, 1);
                    pointer-events: none;
                    cursor: pointer;
                }
                ._7-odd-text, .up-odd-text, .down-odd-text {
                    fill: rgba(255, 204, 0, 1);
                    pointer-events: none;
                    cursor: pointer;
                }
            }
        }
    }
}

