$main-text-color: rgba(255, 255, 255, 1);
$main-text-shadow-color: #000000;
//toolTip
$tooltip-bg-color: #262626;
$tooltip-shadow-color: rgba(0, 0, 0, 0.16);
$tooltip-text-color: #FFFFFF;
$tooltip-arrow-color: #262626;
// icons
$icon-hover-color: #54687e;
$icon-active-color: #54687e;
// gameInfo
$gameInfoItem-bg-color: rgba(29, 27, 45, 0.7);
$gameInfo-bg-color: transparent linear-gradient(90deg, rgba(135, 135, 148, 0.5) 1%, rgba(141, 141, 154, 0.5) 7%, rgba(157, 157, 171, 0.5) 15%, rgba(184, 184, 199, 0.5) 24%, rgba(199, 199, 214, 0.5) 28%, rgba(255, 255, 255, 0.5) 56%, rgba(249, 249, 250, 0.5) 57%, rgba(224, 224, 230, 0.5) 62%, rgba(206, 206, 215, 0.5) 67%, rgba(195, 195, 207, 0.5) 73%, rgba(192, 192, 204, 0.5) 79%, rgba(161, 164, 179, 0.5) 96%) ;
$gameInfo-title-color: #FFFFFF;
$gameInfo-value-color: #FFCC00;
$gameInfo-table-theadText-color: #FFFFFF;
$gameInfo-table-theadShadow-color: #000000;
$gameInfo-table-tbodyText-color: #FFCC00;
$gameInfo-table-tbodyTrHover-color: rgba(#54687E, 0.2);
// winnerList
$winnerList-title-color: #FFCC00;
$winnerList-title-shadow-color: #000000;
$winnerList-table-thead-td-color: #EAEAEC;
$winnerList-table-thead-td-stack-color: #FFCC00;
$winnerList-table-thead-td-shadow-color: #000000;
// histoyr
$history-bg-color: rgba(29, 27, 45, 0.7);
$history-table-head-text-color: #FFFFFF;
$history-table-body-sum-row-color: #FFCC00;
$history-table-body-row-color: #eaeaec;
//modal
$modal-button-bg-color: rgba(#54687e, 0.1);
$modal-button-text-color: #FFFFFF;
$modal-button-bg-hover-color: rgba(#54687e, 0.2);
$modal-button-bg-active-color: rgba(#54687e, 0.4);
//form
$form-label-color: #FFFFFF;
//dropDown
$drop-down-color: #FFFFFF;
$drop-down-bg-color: linear-gradient(180deg, rgba(135, 135, 148, 0.6) 1%, rgba(141, 141, 154, 0.6) 7%, rgba(157, 157, 171, 0.6) 15%, rgba(184, 184, 199, 0.6) 24%, rgba(199, 199, 214, 0.6) 28%, rgba(255, 255, 255, 0.6) 56%, rgba(249, 249, 250, 0.6) 57%, rgba(224, 224, 230, 0.6) 62%, rgba(206, 206, 215, 0.6) 67%, rgba(195, 195, 207, 0.6) 73%, rgba(192, 192, 204, 0.6) 79%, rgba(161, 164, 179, 0.6) 96%);
$drop-down-arrow-active-color: #fc0;
$drop-down-item-bg-hover-color: rgba(84, 104, 126, 0.4);
// datePicker
$datePicker-input-color: #FFFFFF;
$datePicker-header-color: #FFFFFF;
$datePicker-bg-color: rgb(29 27 45 / 50%);
$datePicker-header-shadow-color: #000000;
$datePicker-header-bottom-border-color: rgba(#fc0, 0.999);
$datePicker-footer-text-shadow-color: #000000;
$datePicker-footer-text-color: #FFFFFF;
$datePicker-footer-text-active-color: rgba(#fc0, 0.999);
$datePicker-header-weekDay-color: #eaeaec;
$datePicker-header-mount-weekDay-color: #FFFFFF;
$datePicker-header-mount-weekDay-selected-color: rgba(84, 104, 126, 0.4);
$datePicker-header-mount-weekDay-active-border-color: #FFCC00;
$datePicker-header-arrow-active-color: #FFCC00;
// switch
$switch-bg-color: rgba(#1d1b2d, 0.6);
$switch-circle-cbg-color: #fc0;
$switch-circle-hover-border-color: #fc0;
$switch-circle-hover-bg-color: #1d1b2d;
$switch-label-color: #EAEAEC;
$switch-label-hover-color:  #fc0;
// RadioButton
$radio-button-border-color: #fc0;
$radio-button-bg-color: rgba(#1d1b2d, 0.4);
$radio-button-label-color: #EAEAEC;
$radio-button-label-hover-color: #fc0;
$radio-button-circle-color: #fc0;
// stream
$stream-message-text-color: #FFFFFF;
$stream-message-text-shadow-color: #000000;
$stream-time-text-color: #FFFFFF;
$stream-time-white-border-color: rgba(#FFFFFF, 0.32);;
$stream-time-white-bg-color: radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #B1B1B1 21%, #585858 43%, #000000 72%, #000000 100%);
$stream-time-green-bg-color: radial-gradient(closest-side at 50% 50%, #32D74B 0%, #6EB33C 21%, #305515 43%, #000000 72%, #000000 100%);
$stream-time-green-text-color: #32D74B;
$stream-time-green-border-color: rgba(#32D74B, 0.32);
$stream-time-red-bg-color: radial-gradient(closest-side at 50% 50%, #FF453A 0%, #B33C3C 21%, #551515 43%, #000000 72%, #000000 100%);
$stream-time-red-text-color: #FF453A;
$stream-time-red-border-color: rgba(#FF453A, 0.32);
$stream-time-yellow-bg-color:  radial-gradient(closest-side at 50% 50%, #FF9F0A 0%, #B38A3C 21%, #553F15 43%, #000000 72%, #000000 100%);
$stream-time-yellow-text-color: #FF9F0A;
$stream-time-yellow-border-color: rgba(#FF9F0A, 0.32);
$stream-time-red-color: #FFFFFF;
$stream-time-text-shadow-color: #000000;
$stream-time-ticker-border-color: rgb(255, 255, 255, 0.32);

// betArea
$board-box-bg-color: rgba(234, 234, 236, 0.5);
$board-box-player-b-bg-color: rgba(255, 255, 255, 0.5);
$board-box-border-color: rgb(234, 234, 236);
$board-box-border-blue-color: rgb(114, 187, 239);
$board-box-bg-blue-color: rgba(114, 187, 239, 0.5);
$board-box-border-red-color: rgb(250, 169, 186);
$board-box-bg-red-color: rgba(250, 169, 186, 0.5);

//infoPopUp
$infoPopUp-bg-color: rgba(29, 27, 45, 0.7);
$infoPopUp-content-bg-color: rgba(29, 27, 45, 0.9);
$infoPopUp-content-border-color: rgba(135, 135, 148, 0.5);
$infoPopUp-content-color: rgba(234, 234, 236, 1);
$infoPopUp-content-text-shadow-color: rgba(0, 0, 0, 1);
$infoPopUp-btn-bg-color:rgba(29, 27, 45, 0.9);
$infoPopUp-btn-border-color: rgba(135, 135, 148, 0.5);
$infoPopUp-btn-color: rgba(255, 255, 255, 1);
$infoPopUp-btn-text-shadow-color: rgba(0, 0, 0, 1);
$infoPopUp-btn-active-text-color: rgba(255, 204, 0, 1);
$infoPopUp-btn-hover-border-color: rgba(135, 135, 148, 0.5);
$infoPopUp-btn-active-border-color: rgba(135, 135, 148, 1);

// header
$header-mobile-bg-color: rgba(#1D1B2D, 0.7);

// rightMenu
$rightMenu-text-color: rgba(255, 255, 255, 1);
$rightMenu-text-shadow-color: rgba(0, 0, 0, 1);

// history point color

$history-D-color: #C10028;
$history-D-border-color: #C10028;
$history-U-color: #0072C3;
$history-U-border-color: #0072C3;
$history-7-color: #0FB700;
$history-7-border-color: #0FB700;